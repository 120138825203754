import React from 'react';
import '../App.css';
import '../styles.css';
import pic from "../img/wificall.png";
import useSplash from "../hooks/useSplash";

const IOs = () => {
    const {handleClick} = useSplash();

    return (
        <div className="main">
            <span>
                <img src={pic} alt="" />
            </span>
            <div>
                <div className="link">
                    {/*<h5>@wificallng</h5>*/}
                    <a href="https://instagram.com/wificallng" target="_blank"><img src="https://res.cloudinary.com/teewhy/image/upload/v1560457736/instagram.png" alt=""/></a>
                    <a href="https://twitter.com/wificallng" target="_blank"><img src="https://res.cloudinary.com/teewhy/image/upload/v1560458525/twitter.png" alt=""/></a>
                    <a href="https://web.facebook.com/wificallng/?_rdc=1&_rdr" target="_blank"><img src="https://res.cloudinary.com/teewhy/image/upload/v1560458525/facebook.png" alt=""/></a>
                    <a href="https://www.youtube.com/channel/UCxl6uhRtBZIw8r76wWGxm3w" target="_blank"><img src="https://res.cloudinary.com/teewhy/image/upload/v1560458525/youtube.png" alt=""/></a>
                </div>

                <div className="links"  onClick={handleClick}>
                    <a className="btn btn-link mt-1" name="ios">Download from IOS Appstore</a>
                </div>


            </div>
            <div className="footer">
                <p>{new Date().getFullYear()} © Tizeti Network Limited. All Rights Reserved</p>
            </div>
        </div>

    );
};

export default IOs;