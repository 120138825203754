import axios from "axios";

export const useSplash = () => {
    const downloadFile = async () => {
        try{
            const response = await axios.get(`https://res.cloudinary.com/djvzkeset/video/upload/v1565105238/2018-11-27_-_Like_Ras_-_FesliyanStudios.com_By_Stephen_Bennett.mp3`, {
                responseType: 'blob',
            });
            if(response){
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'WificallBeat.mp3');
                document.body.appendChild(link);
                link.click();
            }
        } catch (e) {
            if(e){
                console.log(e.response);
            }
        }

    };

    const handleClick = async (e) => {

        e.preventDefault();
        const  {name}  =   e.target;

        const data = {
            source: name
        };

        try{
            const response = await axios.post( `https://api.tizeti.com:8443/api/v1/link-count`,data);
            switch (response.data.data.source) {
                case "ios":
                    window.location.href="https://itunes.apple.com/us/app/wificall-ng/id1458874906?ls=1&amp;mt=8";
                  break;
                    case "android":
                        window.location.href="https://play.google.com/store/apps/details?id=com.tizeti.wificall";
                break;
                case "web":
                    window.location.href = "https://myaccount.wificall.ng/#/sign-up";
                break; 
                default:
                    return null;
            }
        } catch (e) {
            if(e){
                console.log(e.response)
            }
        }

    };

    return {downloadFile, handleClick}

};
export default useSplash;