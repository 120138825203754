import React from "react";
import "../App.css";
import "../styles.css";
import pic from "../img/wificall.png";
// import background from "../img/background.jpg";
import axios from "axios";

const Splash = () => {
  const handleClick = (e) => {
    e.preventDefault();
    const data = {
      source: e.target.name,
    };
    axios({
      method: "post",
      url: "https://api.tizeti.com:8443/api/v1/link-count",
      data: data,
    }).then((res) => {
      if (res.data.data.source === "ios") {
        window.location.href =
          "https://itunes.apple.com/us/app/wificall-ng/id1458874906?ls=1&amp;mt=8";
      }
      if (res.data.data.source === "android") {
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.tizeti.wificall";
      }
      if (res.data.data.source === "web") {
        window.location.href = "https://myaccount.wificall.ng/#/sign-up";
      }
    });
  };

  return (
    <div className="main">
      <span>
        <img src={pic} alt="" />
      </span>
      <div>
        <div className="link">
          {/*<h5>@wificallng</h5>*/}
          <a
            href="https://instagram.com/wificallng"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://res.cloudinary.com/teewhy/image/upload/v1560457736/instagram.png"
              alt=""
            />
          </a>
          <a
            href="https://twitter.com/wificallng"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://res.cloudinary.com/teewhy/image/upload/v1560458525/twitter.png"
              alt=""
            />
          </a>
          <a
            href="https://web.facebook.com/wificallng/?_rdc=1&_rdr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://res.cloudinary.com/teewhy/image/upload/v1560458525/facebook.png"
              alt=""
            />
          </a>
          <a
            href="https://www.youtube.com/channel/UCxl6uhRtBZIw8r76wWGxm3w"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://res.cloudinary.com/teewhy/image/upload/v1560458525/youtube.png"
              alt=""
            />
          </a>
        </div>
        <div className="links">
          <button
            type="button"
            className="btn btn-link"
            name="ios"
            onClick={handleClick}
          >
            Download from iOS Appstore
          </button>
        </div>
        <div className="links">
          <button
            type="button"
            className="btn btn-link"
            name="android"
            onClick={handleClick}
          >
            Download from Google Playstore
          </button>
        </div>
        <div className="links">
          <button
            type="button"
            className="btn btn-link"
            name="web"
            onClick={handleClick}
          >
            Visit website
          </button>
        </div>
      </div>
      <div className="footer">
        <p>
          {new Date().getFullYear()} © Tizeti Network Limited. All Rights
          Reserved
        </p>
      </div>
    </div>
  );
};

export default Splash;
