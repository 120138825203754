import React, {Component} from 'react';
import Splash from "./components/Splash";
import {HashRouter as Router, Route} from "react-router-dom";
import Android from "./components/Android";
import IOs from "./components/iOS";

class App extends Component {
    render() {
        return (
            <Router>
                <div className="App">
                    <Route exact path="/" component={Splash} />
                    <Route exact path="/ios" component={IOs} />
                    <Route exact path="/android" component={Android} />
                </div>
            </Router>
        );
    }
}

export default App;
